import React, { Component } from 'react';
import './index.css';

class Home extends Component {
    render() {
        return (
            <div className="saasCom">
                <div className="big-box">
                    <div className="left-box">
                        <div className="title">股权全流程生态服务管理平台</div>
                        <div className="description">SaaS全流程股权服务，伴随企业成长</div>
                        <div className="button-box">
                            <button className="free-btn"
                                    onClick={() => window.open('https://demoweb.sungo.top/', '_blank')}>预约演示
                            </button>
                        </div>
                    </div>
                    <div className="right-box">
                        <img src="/home-banner.png" alt="" className="right-box-img"/>
                    </div>
                </div>
                <div className="small-wrap display-sb" id={"box2"}>
                    <div className="box">
                        <span className="box-tittle">税务及人力咨询</span>
                        <span className="box-description">股权结构设计及税收安排</span>
                        <span className="box-description2">
                            从公司控制权及税收角度 重新设计股权结构，力求风险、成本、 控制、管理的综合平衡
                        </span>
                        <img src="/index1.png" alt="" className={"box-img"}/>
                    </div>
                    <div className="box">
                        <span className="box-tittle" style={{color: '#00dcb1'}}>专业咨询定制服务</span>
                        <span className="box-description">股权专项事务的咨询解决方案</span>
                        <span className="box-description2">
                            基于大数据与轻咨询服务，从方案设计 到实施、管理的一站式 互联网解决方案
                        </span>
                        <img src="/index2.png" alt="" className={"box-img"}/>
                    </div>
                    <div className="box">
                        <span className="box-tittle" style={{color: '#ffa300'}}>SaaS咨询管理</span>
                        <span className="box-description">提供专业的在线化股权可视化管理工具</span>
                        <span className="box-description2">
                            提供专业的在线化股权可视化管理工具
                        </span>
                        <img src="/index3.png" alt="" className={"box-img"}/>
                    </div>
                </div>
                <div className={"small-wrap flex-align-center"} id={"box3"}>
                    <div className={"box-tittle-big"}>一个平台，管理股权所需的一切</div>
                    <div className={"display-sb flex-justify-center flex-justify-between remove-margin-top move-box"}
                         style={{width: '880px', height: '106px'}}>
                        <div className={"box-item"}>
                            <img src="/item-img1.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>Captable和ESOP管理</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item-img2.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>FA</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item-img3.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>股权透明度</span>
                        </div>
                    </div>
                    <div className={"display-sb flex-justify-center flex-justify-between move-box"}
                         style={{width: '880px', height: '106px'}}>
                        <div className={"box-item"}>
                            <img src="/item2-img1.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>证券数字化</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item2-img2.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>融资前景推演</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item2-img3.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>大数据分析引导</span>
                        </div>
                    </div>
                    <div className={"display-sb flex-justify-center flex-justify-between move-box"}
                         style={{width: '880px', height: '106px'}}>
                        <div className={"box-item"}>
                            <img src="/item3-img1.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>报告生成</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item3-img2.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>分配测算</span>
                        </div>
                        <div className={"box-item"}>
                            <img src="/item3-img3.png" alt=""
                                 style={{width: '54px', height: '54px', marginBottom: '28px'}}/>
                            <span className={"box-text"}>税务筹划</span>
                        </div>
                    </div>
                </div>
                <div className={"free-trial-bottom flex-align-center"}>
                    <span className={"free-trial-bottom-text"}>立即使用，体验智能化管理</span>
                    <div className={"buttons"}>
                        <button className={"free-btn"}
                                onClick={() => window.open('https://demoweb.sungo.top', '_blank')}>免费试用
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
