import React, { Component } from 'react';
import "./index.css"

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenuItem: "金融科技", // 初始选中的菜单项为金融科技
            caseSubText: "金融科技", // 初始显示的 case-sub 文字为金融科技
        };
    }

    handleMenuItemClick = (menuItem) => {
        this.setState({
            activeMenuItem: menuItem,
            caseSubText: menuItem,
        });
    }

    render() {
        const { activeMenuItem, caseSubText } = this.state;

        return (
            <div className={"cases-container"}>
                <div className={"cases-top-con"}>
                    <span className={"cases-top-text"}>案例展示</span>
                </div>
                <div className={"small-content"}>
                    <div className={"left"}>
                        <ul className="menu2">
                            <li className={`menu-item-text cursor ${activeMenuItem === "金融科技" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("金融科技")}>金融科技
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "企业服务" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("企业服务")}>企业服务
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "电子商务" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("电子商务")}>电子商务
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "文娱传媒" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("文娱传媒")}>文娱传媒
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "移动互联网" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("移动互联网")}>移动互联网
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "教育" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("教育")}>教育
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "科技连锁" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("科技连锁")}>科技连锁
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "护理培训" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("护理培训")}>护理培训
                            </li>
                            <li className={`menu-item-text cursor ${activeMenuItem === "新媒体" ? "active" : ""}`}
                                onClick={() => this.handleMenuItemClick("新媒体")}>新媒体
                            </li>
                        </ul>
                    </div>
                    <div className={"right"}>
                        <div className="case-sub">{caseSubText}</div>
                        <div className="substance">
                            {activeMenuItem === "金融科技" && (
                                <div className={"finance-tech"}>
                                    <div className={"tittle"}>企业基本情况：</div>
                                    <div className={'paragraph'}>
                                        企业基本情况：公司成立四年，人数近百人，主营业务为基于AI技术的智能投顾服务。作为一家以技术为导向的企业，已吸引多位行业技术大牛及明星VC/PE背景的职业经理人all in 加盟。
                                    </div>
                                    <div className={"tittle"}>客户痛点：</div>
                                    <div className={'paragraph'}>
                                        无论是出于投资人要求或是留住优秀人才角度考虑，股权激励方案设计的诉求均较为急迫。核心团队对公司目前创业项目的认可度较高，但薪资水平较市场有一定差距，鉴于核心团队整体人员素质较高且对期权的认知度较高，创始团队希望能够通过发放期权的模式弥补当前的薪资差距。
                                    </div>
                                    <div className={"tittle"}>方案制定：</div>
                                    <div className={'paragraph'}>
                                        尚股特为本次项目成立以海归成员为主的项目团队，定制精细版尽职调查清单，尽可能将涉及方案细节的相关资料一次性调研清晰，为与公司股东及管理层沟通做充分准备。鉴于公司当前对资本市场规划预期与投资人的实际情况有所出入，在设计过程中，项目团队提供了VIE 架构下及人民币架构下两套不同机制的方案，并进行了详细的对比分析和讲解。 此外，由于公司本次激励计划是在其投资人引导下开始进行推动，因此在方案设计过程中，部分细节设计也加入了其投资人的建议。 针对其薪资折股形式的需求，项目组也对行业薪资进行统筹分析，并和公司管理层重新修订公司当前职级体系，建立长期激励与现金薪酬的合理规划配比方案。
                                    </div>
                                    <div className={"tittle"}>实现效果：</div>
                                    <div className={'paragraph'}>
                                        希望借此方案能够切实提高核心团队的整体激励水平，对新引入员工采用薪资折股模式，既能够达到候选人的心理预期亦可降低公司当前的现金流支出，全心专注于新项目的研发与设计， 相关成熟期与公司业务发展期及资本规划紧密结合，能够完美接入退出机制，使整体方案机制浑然一体。
                                    </div>
                                </div>
                            )}
                            {activeMenuItem === "企业服务" && (
                                <div className={"finance-tech"}>
                                    <div className={"tittle"}>企业基本情况：</div>
                                    <div className={'paragraph'}>
                                        企业基本情况：公司成立五年，规模近千人，主营业务为SaaS服务平台。公司为美元架构（VIE架构）企业，是细分领域中的领头羊。
                                    </div>
                                    <div className={"tittle"}>客户痛点：</div>
                                    <div className={'paragraph'}>
                                        初创时期，向创始团队承诺授予股权，随着公司规模的不断扩大，在人才引进过程中亦不断向核心人员承诺授予期权或者股权，因此股权激励方案落地的诉求越来越急迫，但公司的业务发展仍处于飞速发展的通道中，
                                        没有足够的时间精力对长期激励体系进行系统梳理，因此人才保留、激励和吸引都存在较大的压力和隐患。
                                        公司希望在五周年庆典上公布期权激励计划，时间紧迫，创始团队与尚股咨询团队迅速建立项目团队，紧密沟通，尽快建立一套长期激励体系，解决历史承诺的问题，
                                        同时完善早期的激励计划方案，为公司快速健康发展奠定稳定的长期激励基础。
                                    </div>
                                    <div className={"tittle"}>方案制定：</div>
                                    <div className={"paragraph"}>
                                        经过多次沟通，敲定了项目整体推进计划及关键节点的需求，尽职调查资料与高管团队的访谈工作交错进行，高管团队访谈的过程中普及VIE
                                        架构下股权激励的概念与常规操作思路，同时重点关注历史问题的梳理和分析。
                                    </div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>1</div>
                                        <div className={"paragraph2"}>
                                            梳理公司股权沿革的历史，结合过往股权的承诺和核心高管的切身情况，将历史承诺中不同情境下的股权比例、数量或股权价值进行统一，换算为当下的股数，对于授予价格、
                                            考核机制和退出机制进行完善，妥善设计离职高管的期权处理方案。
                                        </div>
                                    </div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>2</div>
                                        <div className={"paragraph2"}>
                                            在公司已有职级体系的基础上，构建一套适合公司未来2-3
                                            年发展的长期激励体系，保证激励效果足够的前提下，平衡不同序列和职级之间的关系，
                                            对于现有激励对象的激励层级和激励水平作出合理的规划，既要保证对现有员工的激励力度，又要为未来重要岗位和关键人才留出足够的额度。
                                            考虑未来激励对象可能会离开公司，基于公司的长期目标和公平原则，原则上激励对象离开公司时，公司会通过合理的机制回收所有的期权额度，用作后续人才激励使用。
                                            考核机制和退出机制进行完善，妥善设计离职高管的期权处理方案。
                                        </div>
                                    </div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>3</div>
                                        <div className={"paragraph2"}>
                                            项目组完善股权激励方案细则后，与公司创始团队共同研讨方案宣讲方案，包括确定宣讲的主题、议程安排、保密协议的签署、现场环境的布置、线上线下多种形式的展示，
                                            经过精心细致的准备，现场收获非常好的反馈，激励对象对公司的认同感显著增强。
                                            考核机制和退出机制进行完善，妥善设计离职高管的期权处理方案。
                                        </div>
                                    </div>
                                    <div className={"tittle"}>实现效果:</div>
                                    <div className={'paragraph2'}>
                                        希望通过本次股权激励项目能够将期权的历史承诺兑现给一同打拼的兄弟姐妹，让大家共享企业发展的收益，同时对新引进的核心人才的期权方案实施通过系统化的支撑，通过尚股先进的期权管理系统，真正让人才能够看得见激励的效果，让自己的努力能够看得见回报。
                                    </div>
                                </div>
                            )}
                            {activeMenuItem === "电子商务" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    公司成立三年，规模近千人，主营业务为平台代运营。一年内获得多轮融资，发展迅速，目前在行业中处于龙头地位，并在未来有望成为独角兽企业。
                                </div>
                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    企业在拿到A+轮融资后急速扩张，快速扩招运营团队、商务团队、互联网行业人才，并且在多个省市下设分部。
                                    为使新员工快速提升归属感和自主性，同时激发管理团队及核心骨干员工的积极性，希望通过股权激励使员工与公司在未来一起快速成长。
                                    此外，公司对现金流十分重视，希望在前期对股权激励的相关成本给出合理预算及规划，不要影响公司未来现金流。
                                </div>
                                <div className={"tittle"}>方案制定：</div>
                                <div className={'paragraph'}>
                                    整个项目的实施分为前期方案设计与后期方案宣讲。在方案设计阶段，项目组先统筹梳理客户信息，结合行业特征、企业未来的融资及战略规划、
                                    对核心高管的历史承诺等，为激励对象进行股权分配的配比测算，之后结合公司未来海外上市的规划，为公司设计出VIE框架下的股权激励方案。
                                    该方案详细阐述在VIE 背景下未来激励对象行权、成熟、确权、退出等一系列情形，并对公司与激励对象双方的权利及义务进行了明确的约定。
                                </div>
                                <div className={"tittle"}>实现效果：</div>
                                <div className={'paragraph'}>
                                    对于这类在短期经历快速扩张的公司，人才稳定显得尤为重要。股权激励项目作为公司长期激励计划，
                                    能够有效稳定和激励新进高管与核心骨干，并持续发挥激励效果，并且在未来人才招聘中作为富有吸引力的招聘条件吸引更多优秀人才进入公司，让人才管理成为企业核心资本。
                                </div>
                            </div>}
                            {activeMenuItem === "文娱传媒" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    公司成立5年，规模数百人，主营业务为海外短视频生产与分享。
                                </div>
                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    公司希望参与激励计划不设门槛，激励模式个性化设置、行权价格灵活选择、退出机制体现人文关怀，只要求有期权的同学把公司当成自己的家，
                                    让有能力、有贡献的同学都能拿到属于自己的股票期权或股权，一起添砖加瓦，共同建设自己的家园。
                                    没有足够的时间精力对长期激励体系进行系统梳理，因此人才保留、激励和吸引都存在较大的压力和隐患。
                                </div>
                                <div className={"tittle"}>方案制定：</div>
                                <div className={'paragraph'}>
                                    尚股咨询项目组为公司定制了一份个性化的尽职调查清单和工作计划表，删除部分不必要的补充一些历史情况的资料需求，减少正式沟通环节，增加非正式沟通步骤，短平快地处理问题，充分利用现代化通讯工具高效推进工作。
                                    架构下股权激励的概念与常规操作思路，同时重点关注历史问题的梳理和分析。
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>1</div>
                                    <div className={"paragraph2"}>
                                        梳理公司股权沿革的历史，结合过往股权或期权的承诺和核心高管的切身情况，为核心高管设计科学完善的股权激励方案，
                                        通过限制性股权的方式实现激励与约束相结合，解决离职高管的股权处理问题、参与拟激励高管的沟通和协调，将责权利对等起来。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>2</div>
                                    <div className={"paragraph2"}>
                                        为核心骨干设计柔性化的股票期权激励方案，让每个同学都能感受到公司激励机制的初心，同时考虑到激励对象现实的出资压力，人性化考虑不让出资成为大家一起奋斗的阻碍。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>3</div>
                                    <div className={"paragraph2"}>
                                        由项目组和公司的诸多同学共同完成方案的宣讲工作，互联网化的落地方式获得同学们的一致认可。
                                    </div>
                                </div>
                                <div className={"tittle"}>实现效果:</div>
                                <div className={'paragraph'}>
                                    希望股权激励项目能够提供妥善处理核心高管激励问题的解决方案，有效稳定和激励核心高管团队，同时搭建一套较为完备的股权激励体系，为核心骨干员工的股权/期权授予提供依据和支持，同时为未来人才招聘和激励机制的完善打下良好的基础。
                                </div>
                            </div>}
                            {activeMenuItem === "移动互联网" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    公司成立三年，人数近百人，主营业务为移动端APP开发。公司设有公共性平台部门与独立项目组，项目组各负责一款App
                                    。项目组通过内部“创业”，后期考虑独立注册公司。
                                </div>
                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    公共性平台部门、各项目组都需要激励；
                                    股份期权分配，根据激励对象的岗位价值、各项目组的绩效指标完成度、各项目组对公司的贡献做浮动调整；
                                    若项目组未来独立注册公司，则在独立公司内做股权激励，公司股权做相应置换。
                                </div>
                                <div className={"tittle"}>方案制定:</div>
                                <div className={'paragraph'}>
                                    建议不同部门和项目组采用不同的激励方式，平台部门用现有公司股份期权激励，各项目组用内部孵化机制激励。
                                </div>
                                <div className={"tittle2"}>主要原因：</div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>1</div>
                                    <div className={"paragraph2"}>
                                        内部项目立项时间、发展阶段、阶段性贡献各不同，同期比较对公司的贡献来分配期权不合理。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>2</div>
                                    <div className={"paragraph2"}>
                                        项目特点、战略目标、理念、考核方式各不同，很难统一指标或建立同体系的价值逻辑，划分出哪个的贡献更大。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>3</div>
                                    <div className={"paragraph2"}>
                                        各项目相互独立，与公司整体价值关联性有却不直接，利用公司股权激励属“大锅饭”式激励。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>4</div>
                                    <div className={"paragraph2"}>
                                        公司的股份有限，项目孵化是否成功不确定，若设置浮动激励机制，股份分批次分层次的转让及回购手续麻烦。
                                    </div>
                                </div>
                                <div className={"tittle2"}>具体办法：</div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>1</div>
                                    <div className={"paragraph2"}>
                                        母子公司模式，未来孵化成功的项目注册为公司的子公司，平台部门在母公司实施常规公司期权激励。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>2</div>
                                    <div className={"paragraph2"}>
                                        各项目组实施内部孵化激励制度，根据项目发展阶段不同激励手段不同。项目前期，利用薪酬、绩效、奖金激励；项目成熟期，利用利润分红奖金激励；子公司注册后，项目团队可获得子公司初创期原始股及成长期、稳定期的期权激励。
                                    </div>
                                </div>
                                <div className={"number-contents"}>
                                    <div className={"number"}>3</div>
                                    <div className={"paragraph2"}>
                                        以母公司为上市主体，设置母子公司股权置换机制。上市前，母公司回购子公司管理层股份，各项目团队可将原有股份置换为上市主体公司的股份。
                                    </div>
                                </div>
                            </div>}
                            {activeMenuItem === "教育" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    初创公司，以新型在线教育为主要品牌。
                                </div>

                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    前期仅口头许诺了授予股权，承诺以股份占比或股份价值为标准。同时公司面临快速扩张和战略性增长，有大批核心骨干员工需要激励。
                                </div>

                                <div className={"tittle"}>方案制定：</div>
                                <div className={'paragraph'}>
                                    与客户多次沟通，梳理过往不同时点的不同授予情况，完成过往多次授予、赠与、认购与现在以及未来的衔接。确定个量到总量的分配思路，基于老板对每个员工的远期的未来收益，确定激励水平，测算出此次分配股权分配数量，同时配套期权成熟、动态调整和退出机制，达到约束与激励二者较好结合。
                                </div>

                                <div className={"tittle"}>实现效果：</div>
                                <div className={'paragraph'}>
                                    清晰梳理和呈现过往的多次激励情况，落实之前与员工的授予承诺，并与现有激励方案以及未来的计划平稳过度和衔接，实现长效、稳步激励。
                                </div>
                            </div>}
                            {activeMenuItem === "科技连锁" &&
                                <div className={"finance-tech"}>
                                    <div className={"tittle"}>企业基本情况：</div>
                                    <div className={'paragraph'}>
                                        成立四年，规模近千人，主营业务为科技类研发产品，集团旗下有3家公司。其中一家公司为红筹架构的成长型企业。
                                    </div>
                                    <div className={"tittle"}>客户痛点：</div>
                                    <div className={'paragraph'}>
                                        现有方案：现有拆股是否合理，激励方案具体如何执行，按价值授予和按股比授予如何融合，入股价格和退出机制尚未设定，其他法律法规等文件如何正规化。
                                        未来方案：2019 年预计引进人才若干，如何匹配现有方案、激励引进人才。
                                    </div>
                                    <div className={"tittle"}>方案制定：</div>
                                    <div className={'paragraph'}>
                                        规范原有方案核心内容，如有限责任公司的拆股方式、行权价的确定。
                                        梳理原有期权计划数据。
                                        明确期权计划的具体执行方式。
                                    </div>
                                    <div className={"tittle2"}>具体办法：</div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>1</div>
                                        <div className={"paragraph2"}>
                                            期权池释放步骤及原则、激励对象期权配比原则；
                                        </div>
                                    </div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>2</div>
                                        <div className={"paragraph2"}>
                                            激励对象准入机制设计；
                                        </div>
                                    </div>
                                    <div className={"number-contents"}>
                                        <div className={"number"}>3</div>
                                        <div className={"paragraph2"}>
                                            根据企业情况、激励对象的职级、部门和个人能力及绩效表现，适度的融合、匹配、衔接原有方案，针对现有核心人员及未来引进人才
                                            的配比，给出具体建议。
                                        </div>
                                    </div>
                                    <div className={'paragraph'} style={{marginBottom:'14px'}}>
                                        完善严谨的退出机制。根据各类退出情况，如正常退出、调岗、过错离职、退休等，设置不同的退出价格。 境外架构的企业，激励对象无法实际行权。
                                    </div>
                                    <div className={'paragraph'}>
                                        境外上市，监管机构对期权计划的一般要求、上市后员工行权资金流转示意等。
                                    </div>
                                </div>}
                            {activeMenuItem === "护理培训" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    公司成立近三年，规模数百人，主营业务为母婴健康护理机构，公司提供精致化服务和精细化运营，同时建立自营的培训机构进行专门的人才输出，实现了母婴护理从人才制造到服务输出的链条式管理运作。
                                </div>
                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    公司计划在三年内完成上市计划，时间节奏较为紧迫，对公司的业绩增长提出了很大的挑战，因此公司希望通过股权激励计划对不同区域的负责人及总公司的核心骨干带来更大的激励效果，激发员工的积极性，实现公司上市和员工价值实现的双赢。
                                </div>

                                <div className={"tittle"}>方案制定：</div>
                                <div className={'paragraph'}>
                                    项目启动后，项目组与公司创始人当面深入访谈，详细了解公司的历史背景、股权架构及未来的资本规划等一系列相关信息，之后开始为客户进行初步方案的设计。不同于互联网公司采取VIE方式进行海外上市，公司成立初期就计划以中外合资企业的形式进行海外上市，并以此为目的设计相应的股权架构，
                                    这也要求整个方案设计需围绕中外合资企业进行。另外由于激励对象职能差别较大，有的是总公司员工有的是区域负责人，且不同地域的薪资水平差距很大，项目组针对这样的情况进行了详细的配比测算，在强调激励性的同时也需要平衡个体的差异性和内部的公平性。
                                </div>

                                <div className={"tittle"}>实现效果：</div>
                                <div className={'paragraph'}>
                                    针对公司短期内有明确资本规划，人才的稳定性和能动性会极大影响公司战略目标的实施与完成。将股权激励项目作为公司长期激励计划，能够有效稳定和激励核心管理层与骨干员工，最大程度地激发全国各地核心人才的激励性和活力，使员工的利益与公司的价值得到有效统一。
                                </div>

                            </div>}
                            {activeMenuItem === "新媒体" && <div className={"finance-tech"}>
                                <div className={"tittle"}>企业基本情况：</div>
                                <div className={'paragraph'}>
                                    成立四年，是中国知名的新媒体集团公司。
                                </div>
                                <div className={"tittle"}>客户痛点：</div>
                                <div className={'paragraph'}>
                                    通过股权激励，一方面对公司创业以来坚守的核心骨干员工进行历史贡献的奖励，另一方面，公司面临高速发展和业务多元化的趋势，需要通过股权激励吸引一大批优秀的人才加入，提升公司核心竞争力，支撑公司战略发展。
                                </div>

                                <div className={"tittle"}>方案制定：</div>
                                <div className={'paragraph'}>
                                    通过前期对所在行业和客户公司的调研，在充分了解客户实际情况、诉求和痛点的情况下，选定期权作为本次激励计划的工具。期权池兼顾过往和未来，划分不同比例用于不同阶段激励。根据司龄、历史贡献、部门与岗位价值、员工个人能力等维度确定个量分配，同时匹配动态调整和退出机制，兼顾约束与激励相结合。
                                </div>

                                <div className={"tittle"}>实现效果：</div>
                                <div className={'paragraph'}>
                                    对现有员工进行较好的奖励和绑定，同时针对未来即将加入的人才给予充分的选择灵活性，匹配“期权+薪酬”的模式，弥补单一薪酬的不足，并匹配超额利润分配制度，多样化奖励模式，打造了一整套长效激励方式，完善了人才激励体系，充分达到激励和绑定效果。
                                </div>

                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
