import React, {Component} from 'react';
import './index.css'

class Index extends Component {
    render() {
        return (
            <div className={"about-con"}>
                <div className="top-title">
                    <span className={"tittle"}>关于我们</span>
                </div>
                <div className="small-wrap">
                    <div className="about-text">
                        <div className="about-title">关于尚股</div>
                        <div
                            className="about-desc"> 尚股作为国内创新生态服务平台,为高成长企业、新经济、金融机构、产业园区、地方政府提供全方位的咨询、数据研究及分析、资本对接等服务。尚股以企业股权为核心，围绕薪酬架构、税务筹划等多项增值服务，帮助创业者们“发现人”、“留住人”、“发展人”。
                        </div>
                    </div>
                    <div className="about-bg">
                        <img src="/about-sub.png" alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
