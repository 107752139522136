import React, { Component } from 'react';
import './index.css';

class Saas extends Component {
    render() {
        return (
            <div className={"SaaS-container"}>
                <div className={"saas-top-con"}>
                    <div className={"saas-top-text"}>股权激励智能管理系统</div>
                    <div className={"saas-top-text2"}>告别传统的管理模式，做科学高效的全流程在线管理</div>
                    <div className={"saas-top-btn"} onClick={() => window.open('https://demoweb.sungo.top', '_blank')}>免费试用</div>
                </div>
                <div className={"saas-box"} style={{marginTop: '107px'}}>
                    <div className={"saas-box-left"}>
                        <span>股权激励计划管理</span>
                        <span>适用于不同公司架构的股权激励管理，实时反应期权股权的个量与全局变化，实时掌控股权成熟及行权状态，让管理者对股权激励了如指掌。</span>
                    </div>
                    <div className="saas-box-right">
                        <img src="/saas-01.png" alt="" style={{width: "459px", height: "449px"}}/>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className="saas-box-right">
                        <img src="/saas-02.png" alt="" style={{width: "459px", height: "374.5px"}}/>
                    </div>
                    <div className={"saas-box-left"}>
                        <span>智能授予</span>
                        <span>智能配比功能内置6+1个维度的个性化评价体系，基于大数据为企业准确高效地生成多人授予方案。</span>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className={"saas-box-left"}>
                        <span>股权架构管理</span>
                        <span>支持多层持股平台搭建 , 反映最真实的持股情况，并保持数据实时更新。</span>
                    </div>
                    <div className="saas-box-right">
                        <img src="/saas-03.png" alt="" style={{width: "459px", height: "350.31px"}}/>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className="saas-box-right">
                        <img src="/saas-04.png" alt="" style={{width: "459px", height: "343.59px"}}/>
                    </div>
                    <div className={"saas-box-left"}>
                        <span>行权/回购管理</span>
                        <span>支持在线行权和回购，流程便利化，操作方便，为您提高工作效率。</span>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className={"saas-box-left"}>
                        <span>电子协议</span>
                        <span>实现无纸化办公，在线签署、保存相关协议。支持用户导入多个自有文档模板，并可定制签字流程。</span>
                    </div>
                    <div className="saas-box-right">
                        <img src="/saas-05.png" alt="" style={{width: "459px", height: "348.06px"}}/>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className="saas-box-right">
                        <img src="/saas-06.png" alt="" style={{width: "459px", height: "344.72px"}}/>
                    </div>
                    <div className={"saas-box-left"}>
                        <span>数据报表</span>
                        <span>在线生成多种数据报表，数据一目了然，并满足不同报表定制化需求。</span>
                    </div>
                </div>
                <div className={"saas-box"}>
                    <div className={"saas-box-left"}>
                        <span>多权限账户管理</span>
                        <span>员工拥有专属个人账号，可随时管理期权和股权，并查看其授予信息和变化情况。</span>
                    </div>
                    <div className="saas-box-right">
                        <img src="/saas-07.png" alt="" style={{width: "459px", height: "331.44px"}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Saas;
